import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(params) {
    const { from, to, departure, Return, adt, chd, inf, Class } = params;
    return api.get(
      `/flybaghdad/search?from=${from}&to=${to}&departure=${departure}&return=${
        Return ? Return : null
      }&adt=${adt}&chd=${chd}&inf=${inf}&class=${Class}`
    );
  },
  book(data) {
    return api.post(`/flybaghdad/book`, data);
  },
  bookConfirm(pnr) {
    return api.post(`/flybaghdad/booking-confirm?pnr=${pnr}`);
  },
};
