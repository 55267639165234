import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { factory } from "../../api/apiFactory";
const othersApi = factory.get("others");

const initialState = {
  results: {
    loading: false,
    status: true,
    message: "",
    description: "",
    data: [],
  },
  contact_us: {
    loading: false,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.results.loading = !state.results.loading;
    },
    setLoadingContactUs: (state, { payload }) => {
      state.contact_us.loading = !state.contact_us.loading;
    },
    setResult(state, { payload }) {
      if (payload.data != null) {
        state.results = { ...payload };
      } else {
        state.results = { ...payload, data: [] };
      }
      state.results.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setResult, setLoading, setLoadingContactUs } = appSlice.actions;

export default appSlice.reducer;

//axios
// export const getFaqs = () => async (dispatch) => {
//   const key = "faqs"
//   try {
//     dispatch(setLoading(key));
//     const res = await othersApi.Faqs();
//     dispatch(setResult({ key: key, data: res.data }));
//     dispatch(setLoading(key));
//   } catch (err) {
//     dispatch(setLoading(key));
//     throw new Error(err);
//   }
// };

export const fetchInfo = (key) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await othersApi[key]();
    dispatch(setResult(res.data));
  } catch (err) {
    dispatch(setLoading());
    throw new Error(err);
  }
};

export const addContactUs = (data) => async (dispatch) => {
  try {
    dispatch(setLoadingContactUs());
    const res = await othersApi.addContactUs(data);
    toast.success("Message Sent Successfully !");
    dispatch(setLoadingContactUs());
    return res;
  } catch (err) {
    dispatch(setLoadingContactUs());
    return err.response.data;
    // throw new Error(err);
  }
};
