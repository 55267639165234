import Routes from "./routes";
import { useEffect } from "react";
import Notification from "./components/Notification";
import runOneSignal from "./onesignal/config";
import TawkTo from "./tawkChat/configChat";
import "./services/i18n";

const App = () => {
  useEffect(() => {
    runOneSignal();
  });

  // Within your component or a specific function
  return (
    <div>
      <Routes />
      <Notification />
      <TawkTo />
    </div>
  );
};

export default App;
