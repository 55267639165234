import React from "react";
import { Toaster } from "react-hot-toast";

const Notification = () => {
  return (
    <Toaster
    style={{width: '50px', height: '50px'}}
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: "",
        duration: 5000,
        success: {
          duration: 4000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }}
    />
  );
};

export default Notification;
