import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { factory } from "../../api/apiFactory";
const groupsApi = factory.get("groups");
const othersApi = factory.get("others");

const initialState = {
  filters: {
    take: 200,
    skip: 0,
    sort: "",
    search: "",
    country_id: "",
  },
  results: {
    loading: false,
    status: false,
    message: "",
    description: "",
    total: 0,
    data: [],
  },
  group: {
    loading: false,
    status: false,
    message: "",
    description: "",
    data: {
      id: 1,
      rate: "",
      price_adult: "",
      price_child: "",
      price_infant: "",
      price_single_room: "",
      name: "",
      desc: "",
      food: "",
      accommodations: "",
      transportation: "",
      drinks: "",
      tickets: "",
      equipment: "",
      img_main_url: "",
      quantity_seat: 0,
      last_booking: "",
      start: "",
      end: "",
      days_count: 8,
      group_days: [],
      currency: "",
      country: {
        id: 0,
        name: "",
        code: "",
      },
    },
  },
  country:{
    loading: false,
    status: false,
    message: "",
    description: "",
    data: [],
  },
  errors: "",
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      state.results = { ...payload };
      state.results.total = payload.total;
      state.results.loading = false;
    },
    setGroupErrors(state, { payload }) {
      state.errors = payload;
    },
    setGroup(state, { payload }) {
      state.group = { ...payload };
      state.group.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    getCountries(state, { payload }) {
      state.country = { ...payload };
      state.country.loading = false;
    },
  },
});

export const {
  setLoading,
  setResults,
  setGroup,
  setGroupErrors,
  setFilters,
  getCountries,
  resetFilters,
} = groupsSlice.actions;

export default groupsSlice.reducer;

//axios
const getData = () => async (dispatch, getState) => {
  try {
    const filters = getState().groups.filters;
    dispatch(setLoading("results"));
    const res = await groupsApi.getGroups(filters);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    throw new Error(err);
  }
};
const getByID = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("group"));
    const res = await groupsApi.byid(id);
    dispatch(setGroup(res.data));
  } catch (err) {
    dispatch(setLoading("group"));
    throw new Error(err);
  }
};

const book = (form, id, attachments) => async (dispatch) => {
  try {
    const formData = new FormData();

    for (let i = 0; i < attachments.length; i++) {
      formData.append(`files`, attachments[i]);
    }

    const bookInfo = {
      group_id: id,
      passengers: form,
    };
    const book_res = await groupsApi.book(bookInfo);
    if (book_res.data.status) {
      const res_success = book_res.data.data;

      formData.append("booking_id", res_success.id);
      formData.append("pnr", res_success.pnr);

      const attachments_res = await othersApi.attachments(formData);
      if (attachments_res.data.status) {
        toast.success("Successfully Booked!");
        dispatch(setGroupErrors(""));
        return true;
      } else {
        toast.error("Somthing went wrong !");
        dispatch(setGroupErrors(attachments_res.data.description));
      }
    } else {
      toast.error("Somthing went wrong !");
      dispatch(setGroupErrors(book_res.data.description));
    }
  } catch (err) {
    dispatch(setGroupErrors(err.response.data.message));
    toast.error("Somthing went wrong !");
    throw new Error(err);
  }
};

const getCountry = () => async (dispatch) => {
  try {
    dispatch(setLoading("country"));
    const res = await groupsApi.getCountry();
    dispatch(getCountries(res.data));
  } catch (err) {
    dispatch(setLoading("country"));
    throw new Error(err);
  }
};

export const groups = {
  getData,
  book,
  getByID,
  getCountry
};
