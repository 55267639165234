import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { factory } from "../../api/apiFactory";
import SuccessAlert from "../../components/Alerts/SuccessAlert";

const travelportApi = factory.get("travelport");
const othersApi = factory.get("others");


const initialState = {
  search: {
    adults: 1,
    children: 0,
    infants: 0,
    from: "",
    to: "",
    date: [
      {
        startDate: new Date(),
        endDate: dayjs(new Date()).add(7, "day").toDate(),
        key: "selection",
      },
    ],
    trip: "one-way",
    classs: "Economy",
  },
  results: {
    loading: false,
    data: [],
    total_data: 0,
    error_code: 0,
    status: false,
    description: "",
    message: "",
  },
  request_object: {
    segments: [],
    passengers: null,
    travelers: 0,
    pricing: {
      currency: "USD",
    },
    long: true,
    requestId: Math.floor(100000 + Math.random() * 900000),
  },
  trip_details: {
    modal: false,
    flight: {
      directions: [],
    },
    price: {
      loading: false,
      data: {
        farePricingMethod: "Guaranteed",
        platingCarrier: "",
        totalPrice: "",
        basePrice: "",
        taxes: "",
        bookingComponents: [
          {
            totalPrice: "",
            basePrice: "",
            taxes: "",
            uapi_fare_reference: "",
          },
        ],
        passengerCounts: {
          ADT: 1,
        },
        passengerFares: {
          ADT: {
            totalPrice: "",
            basePrice: "",
            taxes: "",
          },
        },
        fareInfo: {
          latestTicketingTime: "",
          eTicketability: "",
          refundable: false,
          changePenalty: {
            amount: "",
            penaltyApplies: "",
          },
          cancelPenalty: {
            percentage: "",
            penaltyApplies: "",
          },
        },
        taxesInfo: [],
        baggage: [],
        timeToReprice: "",
      },
    },
    fare_rules: {
      loading: false,
      data: [],
    },
  },
  book: {
    loading: false,
    errors: "",
  },
};

export const travelportSlice = createSlice({
  name: "travelport",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      if (payload.data != null) {
        state.results = { ...payload.data };
      } else {
        state.results = { ...payload, data: [] };
      }
      state.results.loading = false;
    },
    setPrice(state, { payload }) {
      state.trip_details.price.data = payload;
    },
    setFlight(state, { payload }) {
      state.trip_details.flight = payload;
    },
    setFareRules(state, { payload }) {
      state.trip_details.fare_rules.data = payload;
    },
    setBookErrors(state, { payload }) {
      state.book.errors = payload;
    },
    setRequestObject(state, { payload }) {
      state.request_object = { ...payload };
    },
    toggleTripDetailsModal(state, { payload }) {
      state.trip_details.modal = payload;
    },
    setLoading: (state, { payload }) => {
      switch (payload) {
        case "results":
          state.results.loading = !state.results.loading;
          break;
        case "price":
          state.trip_details.price.loading = !state.trip_details.price.loading;
          break;
        case "fare_rules":
          state.trip_details.fare_rules.loading =
            !state.trip_details.fare_rules.loading;
          break;
        case "book":
          state.book.loading = !state.book.loading;
          break;
        default:
      }
    },
    setGlobalTravelPort(state, { payload }) {
      //flight object
      state.trip_details.flight.directions = payload.directions;
      state.trip_details.price.data.bookingComponents =
        payload.bookingComponents;
      state.trip_details.price.data.passengerCounts = payload.passengerCounts;
      state.trip_details.price.data.passengerFares = payload.passengerFares;
      state.trip_details.price.data.platingCarrier = payload.platingCarrier;
      state.trip_details.price.data.taxes = payload.taxes;
      state.trip_details.price.data.totalPrice = payload.totalPrice;
      state.trip_details.price.data.basePrice = payload.basePrice;
      //request object
      const directions1 = payload.directions[0][0].segments;
      const directions2 = payload.directions.length > 1 ? payload.directions[1][0].segments : [];
      const direction3 = payload.directions.length > 2 ? payload.directions[2][0].segments : [];
      const direction4 = payload.directions.length > 3 ? payload.directions[3][0].segments : [];
      const direction5 = payload.directions.length > 4 ? payload.directions[4][0].segments : [];
      const direction6 = payload.directions.length > 5 ? payload.directions[5][0].segments : [];
      state.request_object.segments = [...directions1, ...directions2, ...direction3, ...direction4, ...direction5, ...direction6];
      state.request_object.passengers = payload.passengerCounts;
      state.request_object.travelers =
        payload.passengerCounts.ADT +
        payload.passengerCounts?.CNN +
        payload.passengerCounts?.INF;
    },
  },
});

export const {
  setSearchValues,
  setLoading,
  setResults,
  setRequestObject,
  toggleTripDetailsModal,
  setPrice,
  setFareRules,
  setFlight,
  setBookErrors,
  setGlobalTravelPort,
} = travelportSlice.actions;

export default travelportSlice.reducer;

//axios
const search = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("results"));
    const res = await travelportApi.search(params);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    throw new Error(err);
  }
};
const fareRulesDetails = () => async (dispatch, getState) => {
  try {
    const request_object = getState().travelport.request_object;
    dispatch(setLoading("fare_rules"));
    const res = await travelportApi.fareRules(request_object);
    dispatch(setFareRules(res.data.data));
    dispatch(setLoading("fare_rules"));
  } catch (err) {
    dispatch(setLoading("fare_rules"));
    throw new Error(err);
  }
};
const airPriceDetails = () => async (dispatch, getState) => {
  try {
    const request_object = getState().travelport.request_object;
    dispatch(setLoading("price"));
    const res = await travelportApi.airPrice(request_object);
    dispatch(setPrice(res.data.data));
    dispatch(setLoading("price"));
  } catch (err) {
    dispatch(setLoading("price"));
    SuccessAlert("Unable to get price details", "error");
  }
};
const book = (form, contact, attachments) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("book"));

    let existAttachments = false;
    const request_object = getState().travelport.request_object;
    const formData = new FormData();

    for (let i = 0; i < attachments.length; i++) {
      existAttachments = true;
      // formData.append(`files[${i}]`, JSON.stringify(attachments[i]));
      formData.append(`files[${i}]`, attachments[i]);
    }

    const bookInfo = {
      segments: request_object.segments,
      passengers: form,
      phone: contact,
      allowWaitlist: true,
    };

    const book_res = await travelportApi.book(bookInfo);

    if (book_res.data.status) {
      if (existAttachments) {
        const booking_res_success = book_res.data.data;
        formData.append("booking_id", booking_res_success.id);
        formData.append("pnr", booking_res_success.pnr);
        const attachments_res = await othersApi.attachments(formData);
        if (attachments_res.data.status) {
          toast.success("Successfully uploaded attachments !");
          dispatch(setBookErrors(""));
        } else {
          toast.error("Somthing went wrong uploading attachments !");
          dispatch(setBookErrors(attachments_res.data.description));
        }
      }
      toast.success("Successfully Booked!");
      // window go to profile page
      window.location.href = "/profile";
      setLoading("book");
    } else {
      toast.error("Somthing went wrong !");
      setLoading("book");
      dispatch(setBookErrors(book_res.data.description));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

  } catch (err) {
    setLoading("book");

    // dispatch(setBookErrors(err.response.data.description));
    throw new Error(err);
  }
};

export const travelport = {
  search,
  fareRulesDetails,
  airPriceDetails,
  book,
};
