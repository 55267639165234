import travelport from "./endpoints/Travelport";
import profile from "./endpoints/Profile";
import others from "./endpoints/Others";
import flyBaghdad from "./endpoints/FlyBaghdad";
import groups from "./endpoints/Groups";
import visa from "./endpoints/Visa";
import hotels from "./endpoints/Hotels";
import airArabia from "./endpoints/AirArabia";
import turkish from "./endpoints/Turkish";
import global from "./endpoints/Global";
import flydubai from "./endpoints/FlyDubai";
import pegasus from "./endpoints/Pegasus";

const repositories = {
  travelport,
  flyBaghdad,
  profile,
  groups,
  others,
  visa,
  hotels,
  airArabia,
  turkish,
  flydubai,
  pegasus,
  global,
};

export const factory = {
  get: (name) => repositories[name],
};
