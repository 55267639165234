import { current, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { factory } from "../../api/apiFactory";
const airArabiaApi = factory.get("airArabia");

const initialState = {
  results: {
    loading: false,
    data: {
      segments: [],
      sessionId: "",
      transactionIdentifier: "",
      DirectionInd: "",
      from: "",
      to: "",
      adt: 0,
      chd: 0,
      inf: 0,
      baseFare: "",
      totalFare: "",
      Currency: "",
      bundles: {
        out: [],
      },
    },
    error_code: 0,
    status: false,
    description: "",
    message: "",
  },
  trip_selections: {
    baggage: [],
    loading_baggage: false,
    meals: [],
    loading_meals: false,
    seats: [],
    loading_seats: false,
  },
  final_price: {
    segments: [],
    bundles: {
      out: "",
    },
    specialDetails: {
      BaggageRequest: [],
      MealRequests: [],
      SeatRequests: [],
    },
    price: [],
    loading: false,
  },
  book: {
    errors: "",
    loading: false,
  },
  is_global: false,
};

export const airArabiaSlice = createSlice({
  name: "airArabia",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      state.results.data = payload.data;
      state.results.status = payload.status;
      state.results.error_code = payload.error_code;
      state.results.description = payload.description;
      state.results.message = payload.message;
      // console.log(payload.message);
      const bundles = payload.data?.bundles;

      if (bundles) {
        state.final_price.bundles.out = bundles.out[0].ns1bunldedServiceId;
      }

      if (bundles?.in) {
        state.final_price.bundles.in = bundles.in[0].ns1bunldedServiceId;
      } else {
      }
      state.results.loading = false;
    },
    setSelectedSegmants(state, { payload }) {
      state.final_price.segments = payload;
    },
    selectedBundle(state, { payload }) {
      state.final_price.bundles[payload.type] =
        payload.data.ns1bunldedServiceId;
    },
    selectedBaggage(state, { payload }) {
      state.final_price.price = initialState.final_price.price;

      const trip_info = current(
        state.trip_selections.baggage[payload.selectedSegment]["@attributes"]
      );

      const check_flight_baggage =
        state.final_price.specialDetails.BaggageRequest.filter(
          (baggage) =>
            baggage.FlightNumber === trip_info.FlightNumber &&
            baggage.TravelerRefNumberRPHList === payload.selectedTraveller
        );

      if (check_flight_baggage.length === 0) {
        //if no baggage added to flight

        const data = {
          baggageCode: payload.baggage.ns1baggageCode,
          TravelerRefNumberRPHList: payload.selectedTraveller,
          FlightRefNumberRPHList: trip_info.RPH,
          DepartureDateTime: trip_info.DepartureDateTime,
          FlightNumber: trip_info.FlightNumber,
        };
        state.final_price.specialDetails.BaggageRequest.push(data);
      } else {
        //remove flight baggage
        state.final_price.specialDetails.BaggageRequest =
          state.final_price.specialDetails.BaggageRequest.filter(
            (baggage) =>
              baggage.FlightNumber !== trip_info.FlightNumber ||
              baggage.TravelerRefNumberRPHList !== payload.selectedTraveller
          );
        //add flight baggage
        const data = {
          baggageCode: payload.baggage.ns1baggageCode,
          TravelerRefNumberRPHList: payload.selectedTraveller,
          FlightRefNumberRPHList: trip_info.RPH,
          DepartureDateTime: trip_info.DepartureDateTime,
          FlightNumber: trip_info.FlightNumber,
        };
        state.final_price.specialDetails.BaggageRequest.push(data);
      }
    },
    selectedMeal(state, { payload }) {
      state.final_price.price = initialState.final_price.price;

      const adt = state.results.data.adt;
      const chd = state.results.data.chd;

      const total = adt + chd;
      const trip_info = current(
        state.trip_selections.meals[payload.selectedSegment]
          .ns1FlightSegmentInfo["@attributes"]
      );

      const check_flight_meal =
        state.final_price.specialDetails.MealRequests.filter(
          (meal) => meal.FlightNumber === trip_info.FlightNumber
        );

      if (check_flight_meal.length === 0) {
        //if no meal added to flight
        for (let i = 1; i <= total; i++) {
          const data = {
            mealCode: payload.meal.ns1mealCode,
            mealQuantity: 1,
            TravelerRefNumberRPHList: i <= adt ? `A${i}` : `C${i}`,
            FlightRefNumberRPHList: trip_info.RPH,
            DepartureDateTime: trip_info.DepartureDateTime,
            FlightNumber: trip_info.FlightNumber,
          };
          state.final_price.specialDetails.MealRequests.push(data);
        }
      } else {
        //remove flight meal
        state.final_price.specialDetails.MealRequests =
          state.final_price.specialDetails.MealRequests.filter(
            (meal) => meal.FlightNumber !== trip_info.FlightNumber
          );
        //add flight meal
        for (let i = 1; i <= total; i++) {
          const data = {
            mealCode: payload.meal.ns1mealCode,
            mealQuantity: 1,
            TravelerRefNumberRPHList: i <= adt ? `A${i}` : `C${i}`,
            FlightRefNumberRPHList: trip_info.RPH,
            DepartureDateTime: trip_info.DepartureDateTime,
            FlightNumber: trip_info.FlightNumber,
          };
          state.final_price.specialDetails.MealRequests.push(data);
        }
      }
    },
    selectSeat(state, { payload }) {
      state.final_price.price = initialState.final_price.price;

      const adt = state.results.data.adt;
      const chd = state.results.data.chd;
      const total = adt + chd;

      const seat_requests = state.final_price.specialDetails.SeatRequests;

      const check_flight_seats = seat_requests.filter(
        (seat) => seat.FlightRefNumberRPHList === payload.selectedFlightRph
      );
      const check_flight_same_seat_number = seat_requests.filter(
        (seat) =>
          seat.FlightRefNumberRPHList === payload.selectedFlightRph &&
          seat.SeatNumber === `${payload.row}${payload.seat}`
      );

      if (check_flight_same_seat_number.length === 0) {
        if (check_flight_seats.length + 1 <= total) {
          const data = {
            SeatNumber: `${payload.row}${payload.seat}`,
            FlightRefNumberRPHList: payload.selectedFlightRph,
            TravelerRefNumberRPHList:
              check_flight_seats.length + 1 <= adt
                ? `A${check_flight_seats.length + 1}`
                : `C${check_flight_seats.length + 1}`,
          };
          state.final_price.specialDetails.SeatRequests.push(data);
        }
      } else {
        state.final_price.specialDetails.SeatRequests =
          state.final_price.specialDetails.SeatRequests.filter(
            (seat) =>
              seat.FlightRefNumberRPHList !== payload.selectedFlightRph ||
              seat.SeatNumber !== `${payload.row}${payload.seat}`
          );
      }
    },
    setTripSelectionsResults(state, { payload }) {
      switch (payload.type) {
        case "meals":
          state.trip_selections.meals = payload.data.data;
          break;
        case "seats":
          state.trip_selections.seats = payload.data.data;
          break;
        case "baggage":
          const baggage = payload.data.data;
          state.trip_selections.baggage = baggage;
          break;
        default:
          break;
      }
    },
    setLoading(state, { payload }) {
      state[payload].loading = !state[payload].loading;
    },
    setLoadingTripSelections(state, { payload }) {
      state.trip_selections[payload] = !state.trip_selections[payload];
    },
    setBookErrors(state, { payload }) {
      state.book.errors = payload;
    },
    clearFinalPrice(state) {
      state.final_price = initialState.final_price;
      state.trip_selections = initialState.trip_selections;
    },
    clearSeatBaggageAndMeal(state) {
      state.final_price.specialDetails =
        initialState.final_price.specialDetails;
      state.final_price.price = initialState.final_price.price;
    },
    removeBaggage(state, { payload }) {
      state.final_price.price = initialState.final_price.price;

      state.final_price.specialDetails.BaggageRequest =
        state.final_price.specialDetails.BaggageRequest.filter(
          (baggage) =>
            baggage.FlightNumber !== payload.FlightNumber ||
            baggage.TravelerRefNumberRPHList !== payload.selectedTraveller
        );
    },
    setFullPrice(state, { payload }) {
      state.final_price.price = payload;
      state.final_price.loading = false;
    },
    toggleGloabalFetch(state) {
      state.is_global = !state.is_global;
    },
    setGlobalAirArabia(state, { payload }) {
      state.results.data = { ...payload };
      state.final_price.segments = payload.segments;
      state.is_global = true;
    },
  },
});

export const {
  setSearchValues,
  setLoading,
  setResults,
  setSelectedSegmants,
  setTripSelectionsResults,
  setTripSelectedPreferences,
  selectedBundle,
  setLoadingTripSelections,
  selectedBaggage,
  selectedMeal,
  selectSeat,
  setFlight,
  setBookErrors,
  clearFinalPrice,
  clearSeatBaggageAndMeal,
  removeBaggage,
  setFullPrice,
  setGlobalAirArabia,
  toggleGloabalFetch,
} = airArabiaSlice.actions;

export default airArabiaSlice.reducer;

//axios
const search = (params) => async (dispatch) => {
  try {
    dispatch(clearFinalPrice());
    dispatch(setLoading("results"));
    const res = await airArabiaApi.search(params);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    throw new Error(err);
  }
};

const showDetails = (Return) => async (dispatch, getState) => {
  try {
    const {
      data: {
        sessionId,
        transactionIdentifier,
        DirectionInd,
        from,
        to,
        adt,
        chd,
        inf,
      },
    } = getState().airArabia.results;
    // const {

    // } = data;
    const { segments } = getState().airArabia.final_price;
    const request_object = {
      sessionId,
      transactionIdentifier,
      DirectionInd,
      from,
      to,
      adt,
      chd,
      inf,
      segments,
    };
    dispatch(setLoadingTripSelections("loading_baggage"));
    const res_baggage = await airArabiaApi.baggage(request_object);
    dispatch(
      setTripSelectionsResults({
        type: "baggage",
        data: res_baggage.data,
        Return,
      })
    );
    dispatch(setLoadingTripSelections("loading_baggage"));
    dispatch(setLoadingTripSelections("loading_meals"));
    const res_meal = await airArabiaApi.meal(request_object);
    dispatch(
      setTripSelectionsResults({
        type: "meals",
        data: res_meal.data,
        Return,
      })
    );
    dispatch(setLoadingTripSelections("loading_meals"));

    dispatch(setLoadingTripSelections("loading_seats"));
    const res_seat_map = await airArabiaApi.setMap(request_object);
    dispatch(
      setTripSelectionsResults({
        type: "seats",
        data: res_seat_map.data,
        Return,
      })
    );
    dispatch(setLoadingTripSelections("loading_seats"));
    // dispatch(setLoading("fare_rules"));
  } catch (err) {
    // dispatch(setLoading("fare_rules"));
    throw new Error(err);
  }
};

const getFullPrice = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("final_price"));
    const {
      sessionId,
      transactionIdentifier,
      DirectionInd,
      from,
      to,
      adt,
      chd,
      inf,
    } = getState().airArabia.results.data;
    const fullPrice = getState().airArabia.final_price;
    const data = {
      sessionId,
      transactionIdentifier,
      DirectionInd,
      from,
      to,
      adt,
      chd,
      inf,
      segments: fullPrice.segments,
      bundles: fullPrice.bundles,
      specialDetails: {
        ...fullPrice.specialDetails,
      },
    };
    const res = await airArabiaApi.price(data);
    dispatch(setFullPrice(res.data));
    // dispatch(setFlight(res.data));
    // dispatch(setLoading("final_price"));
  } catch (err) {
    dispatch(setLoading("final_price"));
    throw new Error(err);
  }
};

const Book = (form, attachments) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("book"));
    const {
      sessionId,
      transactionIdentifier,
      DirectionInd,
      from,
      to,
      adt,
      chd,
      inf,
    } = getState().airArabia.results.data;
    const fullPrice = getState().airArabia.final_price;
    const data = {
      sessionId,
      transactionIdentifier,
      DirectionInd,
      from,
      to,
      adt,
      chd,
      inf,
      total_price: fullPrice.price.data.totalFare,
      segments: fullPrice.segments,
      bundles: fullPrice.bundles,
      specialDetails: {
        ...fullPrice.specialDetails,
      },
      airTravelers: form,
      attachments,
    };
    const res = await airArabiaApi.book(data);

    if (res.data.status === false) {
      dispatch(setBookErrors(res.data.description));
      dispatch(setLoading("book"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    dispatch(setLoading("book"));
    toast.success("Successfully Booked!");
    window.location.href = "/profile";
  } catch (err) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    toast.error("Somthing went wrong !");
    dispatch(setLoading("book"));
    dispatch(setBookErrors(err.response.data.description));
    throw new Error(err);
  }
};

export const airArabia = {
  search,
  showDetails,
  getFullPrice,
  Book,
};
