import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileMenu from "./ProfileMenu";
import { useEffect } from "react";
import { profile } from "src/store/slices/profileSlice";
import Cookies from "js-cookie";

export default function Navbar() {
  const execute = useDispatch();

  useEffect(() => {
    const token = Cookies.get("token_mtn_jwt");
    if (token) execute(profile.getProfile());
  }, []);

  return (
    <nav className="sticky top-0 w-full  shadow-2xl z-40 p-0 bg-white border-1 border-solid border-gray-200 rounded-xl">
      <div className="flex justify-between items-center mx-5">
        <Link href="/" className="text-2xl font-bold flex items-center">
          <img src={require(`../../assets/icons/main_logo.webp`)} alt="logo" className="h-10 w-10" style={{marginLeft: '20px',marginBottom: "8px"}} />
          <span className="text-xl pt-1.5 px-4">
            Booking Travel
          </span>
        </Link>
        <ProfileMenu />
      </div>
    </nav>
  );
}
