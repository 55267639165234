import Sidebar from "../components/Navigation/Sidebar";
import Navbar from "../components/Navigation/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const MainLayout = () => {
  return (
    <div className="flex flex-col background-container" >
      <Navbar />
      <div className="flex flex-1 ">
        <Sidebar />
        <main className="container mx-auto md:w-4/5 w-11/12">{<Outlet />}</main>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
