import React from "react";
import {
    AiOutlineFacebook,
    AiOutlineInstagram,
    AiOutlineYoutube,
} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer className="flex flex-col gap-4 items-center text-white bg-[#212A31] w-full h-full pb-5 pt-5">
            {/*<div className="flex gap-4">*/}
            {/*  <a href="flights/air-arabia">{t("sidebar.airarabia")}</a>*/}
            {/*  <a href="flights/fly-baghdad">{t("sidebar.flybgd")}</a>*/}
            {/*  <a href="flights/travelport">{t("sidebar.travelport")}</a>*/}
            {/*  <a href="flights/turkish-airlines">{t("sidebar.turkish")}</a>*/}
            {/*</div>*/}
            <div className="flex gap-4">
                <a href="/flights">{t("Flights")}</a>
                <a href="/hotels">{t("sidebar.hotels")}</a>
                <a href="/groups">{t("sidebar.groups")}</a>
                <a href="/visa"> {t("sidebar.visa")}</a>
            </div>
            {/*<p className="w-11/12  text-center p-5 text-gray-400">*/}
            {/*  {t("home.footer")}*/}
            {/*</p>*/}
            <div className="flex flex-row gap-4 ">
                <a href="https://www.facebook.com/">
                    <AiOutlineFacebook className="text-4xl"/>
                </a>
                <a href="https://www.instagram.com/">
                    <AiOutlineInstagram className="text-4xl"/>
                </a>
            </div>
            {/*  Powered by  */}
            <div className="flex flex-row gap-4 ">
                <p className="text-white-400 text-sm font-bold" style={{fontFamily: "poppins"}}>
                    <a href="https://cloudfares.net">© {new Date().getFullYear()} CloudFares IT Company. All rights
                        reserved.</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
