import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import Cookies from "js-cookie";
import OneSignal from "react-onesignal";
import { toast } from "react-hot-toast";
import { Mixpanel } from "../../mixpanel/Mixpanel";
import produce from 'immer';
import notification from "../../components/Notification";

const profileApi = factory.get("profile");

const initialState = {
  profile: {
    lang: "en",
    direction: "ltr",
    name: "",
    email: "",
    phone: null,
    email_verified_at: null,
    balance: 0,
    currency: "USD",
    logo: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=1380",
    total_booking: 0,
    country: "Iraq",
    full_address: "",
    loading: false,
    status: false,
  },
  transactions: {
    data: [],
    total: 0,
    loading: false,
    filters: {
      take: 15,
      skip: 0,
      search: "",
    },
  },
  booking: {
    data: [],
    description: "",
    message: "",
    status: null,
    loading: false,
    filters: {
      take: 15,
      skip: 0,
      search: "",
    },
    dialog: {
      open: false,
      loading: false,
      data: {
        id: null,
        types: null,
      },
    },
  },
  notification: {
    data: [],
    total: 0,
    loading: false,
    filters: {
      take: 15,
      skip: 0,
    },
  },
  commission: {
    data: [],
    total: 0,
    loading: false,
    filters: {
      take: 15,
      skip: 0,
      search: "",
    },
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, { payload }) {
      if (payload) {
        state.profile = { ...payload.data, status: payload.status };
      } else {
        state.profile = { ...initialState.profile };
      }
      state.profile.loading = false;
    },
    setTransactionsFilters(state, { payload }) {
      state.transactions.filters = {
        ...state.transactions.filters,
        ...payload,
      };
    },
    setBookingsFilters(state, { payload }) {
      state.booking.filters = {
        ...state.booking.filters,
        ...payload,
      };
    },
    setNotificationFilters(state, { payload }) {
      state.notification.filters = {
        ...state.notification.filters,
        ...payload,
      };
    },
    setCommissionFilters(state, { payload }) {
      state.commission.filters = {
        ...state.commission.filters,
        ...payload,
      };
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setTransactions(state, { payload }) {
      state.transactions.data = payload.data;
      state.transactions.total = payload.total;
      state.transactions.loading = false;
    },
    setBookingHistory(state, { payload }) {
      state.booking.data = payload.data;
      state.booking.total = payload.total;
      state.booking.loading = false;
    },
    setCommission(state, { payload }) {
      // return produce(state, (draft) => {
      state.commission.data = payload.data;
      state.commission.total = payload.total;
      state.commission.loading = false;
      // });
    },
    setNotificationHistory(state, { payload }) {
      state.notification.data = payload.data;
      state.notification.total = payload.total;
      state.notification.loading = false;
    },
    setBookingTransactionDialog(state, { payload }) {
      state.booking.dialog = {
        open: !state.booking.dialog.open,
        data: {
          id: payload?.id ? payload.id : null,
          types: payload?.types ? payload.types : null,
        },
      };
    },
  },
});

export const {
  setLoading,
  setProfile,
  setTransactions,
  setBookingHistory,
  setCommission,
  setCommissionFilters,
  setTransactionsFilters,
  setBookingsFilters,
  setNotificationHistory,
  setNotificationFilters,
  setBookingTransactionDialog,
} = profileSlice.actions;

export default profileSlice.reducer;

//axios
const login = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("profile"));
    const res = await profileApi.login(data);
    Cookies.set("token_mtn_jwt", res.data.data.token, {
      expires: 7,
    });

    // set language in Cookie
    Cookies.set("lang", res.data.data.user.lang, {
      expires: 100,
    });

    dispatch(setProfile({ data: res.data.data.user }));
    Mixpanel.identify(res.data.data.user.id);
    Mixpanel.people.set({
      $email: res.data.data.user.email,
      $name: res.data.data.user.name,
      $language: res.data.data.user.lang,
      $balance: res.data.data.user.balance,
      $currency: res.data.data.user.currency,
      $direction: res.data.data.user.direction,
      $full_address: res.data.data.user.full_address,
      $phone: res.data.data.user.phone,
      $total_booking: res.data.data.user.total_booking,
    });
    OneSignal.setExternalUserId(res.data.data.user.notification_id);
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch(setLoading("profile"));
    throw new Error(err);
  }
};
const resetPassword = (data) => async (dispatch) => {
  try {
    const res = await profileApi.resetPassword(data);
    return res.data;
  } catch (err) {
    toast.error(err.response.data.message);
    throw new Error(err);
  }
};
const getProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading("profile"));
    const res = await profileApi.profile();
    dispatch(setProfile(res.data));
    OneSignal.setExternalUserId(res.data.data.notification_id);
  } catch (err) {
    toast.error(err.response.data?.message);
    dispatch(setLoading("profile"));
    Cookies.remove("token_mtn_jwt");
    dispatch(setProfile());
    throw new Error(err);
  }
};
const getTransactions = () => async (dispatch, getState) => {
  try {
    const { transactions } = getState().profile;
    dispatch(setLoading("transactions"));
    const res = await profileApi.transactions(transactions.filters);
    dispatch(setTransactions(res.data));
  } catch (err) {
    dispatch(setLoading("transactions"));
    throw new Error(err);
  }
};
const getBookingHistory = () => async (dispatch, getState) => {
  try {
    const { booking } = getState().profile;
    dispatch(setLoading("booking"));
    const res = await profileApi.booking(booking.filters);
    dispatch(setBookingHistory(res.data));
  } catch (err) {
    dispatch(setLoading("booking"));
    throw new Error(err);
  }
};

const getCommission = () => async (dispatch, getState) => {
  try {
    const { commission } = getState().profile;
    dispatch(setLoading("commission"));
    const res = await profileApi.commissions(commission.filters);
    dispatch(setCommission(res.data));
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch(setLoading("commission"));
    throw new Error(err);
  }
};

const getNotificationsHistory = () => async (dispatch, getState) => {
  try {
    const { notification } = getState().profile;
    dispatch(setLoading("notification"));
    const res = await profileApi.notificationsHistory(notification.filters);
    dispatch(setNotificationHistory(res.data));
  } catch (err) {
    dispatch(setLoading("notification"));
    throw new Error(err);
  }
};

const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading("profile"));
    await profileApi.logout();
    Cookies.remove("token_mtn_jwt");
    dispatch(setProfile());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch(setLoading("profile"));
    throw new Error(err);
  }
};

const confirmBooking = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("booking"));
    await profileApi.confirmBooking(params);
    dispatch(setBookingTransactionDialog());
    toast.success("Booking Confirmed");
    dispatch(getBookingHistory());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch(setLoading("booking"));
    throw new Error(err);
  }
};
const changeLang = (lang) => async () => {
  try {
    await profileApi.changeLanguage(lang);

    // set language in Cookie
    Cookies.set("lang", lang, {
      expires: 100,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    throw new Error(err);
  }
};

export const profile = {
  login,
  getProfile,
  getBookingHistory,
  getCommission,
  getNotificationsHistory,
  getTransactions,
  logout,
  resetPassword,
  confirmBooking,
  changeLang,
};
