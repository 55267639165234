import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginModal from "src/components/Login/LoginModal";
import { BiIdCard } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { Popover, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { profile } from "src/store/slices/profileSlice";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import numeral from "numeral";
import { SlWallet } from "react-icons/sl";
import { IoNotifications } from "react-icons/io5";



const ProfileTitle = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

    const variants = {
        initial: { opacity: 0, y: -10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
    };

  const logout = () => {
    dispatch(profile.logout()).then(() => {
      navigate("/");
    });
  };

  return (
    <motion.div
        className="flex items-center space-x-6"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}
    >
        <Balance balance={user?.wallets[0]?.balance} currency={user?.wallets[0]?.slug} />
        <Balance balance={user?.wallets[1]?.balance} currency={user?.wallets[1]?.slug} />

        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.1 }}
            className="relative inline-block"
        >
            <select
                value={i18n.language}
                className="text-sm font-medium text-gray-700 h-10 px-3 py-1 border border-gray-300 rounded-full"
                onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                    localStorage.setItem("lang", e.target.value);
                    dispatch(profile.changeLang(e.target.value));
                }}
            >
                <option value="ar">🇮🇶 Arabic</option>
                <option value="en">🇺🇸 English</option>
                <option value="tr">🇹🇷 Turkish</option>
            </select>
        </motion.div>
        <NotificationBadge notificationCount={10} />

      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
            ${open ? "" : "text-opacity-90"}
            group inline-flex items-center rounded-md px-1 py-2 text-base font-medium  hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-black">{user?.name}</span>
                </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-40 -translate-x-1/2 transform px-4 sm:px-0 ">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="flex  items-center bg-gray-50 p-2 hover:bg-gray-100 ">
                    <button
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <span className="flex items-center gap-2  text-sm font-medium text-gray-700">
                        <BiIdCard size={25} />
                        {t("profile.profile")}
                      </span>
                      {/*<span className="block text-xs text-gray-500">*/}
                      {/*  {t("profile.profileinfo")}*/}
                      {/*</span>*/}
                    </button>
                  </div>
                  <div className="border w-full my-1"></div>
                  <div className="flex  items-center bg-gray-50 p-2 hover:bg-gray-100">
                    <button
                      onClick={() => {
                        logout();
                      }}
                    >
                      <span className="flex items-center gap-2  text-sm font-medium text-gray-900">
                        <FiLogOut size={25} />
                        {t("profile.logout")}
                      </span>
                      {/*<span className="block text-xs text-gray-500">*/}
                      {/*  {t("profile.signout")}*/}
                      {/*</span>*/}
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </motion.div>
  );
};

const ProfileMenu = () => {
    const { profile } = useSelector((state) => state.profile);

    return (
        <AnimatePresence mode="wait">
            {profile.email === "" ? (
                <motion.div
                    key="loginModal"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                >
                    <LoginModal />
                </motion.div>
            ) : (
                <motion.div
                    key="profileTitle"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                >
                    <ProfileTitle user={profile} />
                </motion.div>
            )}
        </AnimatePresence>
    );
};


const Balance = ({ balance, currency}) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.1 }}
            className="flex items-center"
        >
            <div className="ml-4">
                <span className="block text-xl font-bold text-teal-600">{numeral(balance).format("0,0")}
                    <span className="text-xl ml-1.5 text-green-950">{currency}</span>
                </span>
                {/*<span className="text-sm text-gray-500">Current Balance</span>*/}
            </div>
            <div className="w-12 h-12 flex items-center justify-center">
                <SlWallet size={30} className="text-orange-500" />
            </div>
        </motion.div>
    );
};


const NotificationBadge = ({ notificationCount }) => {
    const badgeColor =
        notificationCount > 0
            ? notificationCount > 10
                ? 'bg-red-500 text-white'
                : 'bg-red-500 text-white'
            : 'bg-gray-300 text-gray-500';

    return (
        <div className="relative">
            {/* Notification icon */}
            <div className="w-10 h-10 flex items-center justify-center">
                <IoNotifications size={25} className="text-gray-500" />
            </div>

            {/* Notification badge */}
            {notificationCount > 0 && (
                <div className={`absolute top-0 right-0 ${badgeColor} rounded-full p-0.5`}>
                    *
                </div>
            )}
        </div>
    );
};


export default ProfileMenu;
