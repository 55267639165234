import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  attachments(data) {
    return api.post(`/attachment/uploads`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  faqs() {
    return api.get(`/general/faq?take=50&skip=0`);
  },
  aboutUs() {
    return api.get(`/general/about_us`);
  },
  privacyPolicy() {
    return api.get(`/general/privacy_policy?take=25&skip=0`);
  },
  contactUs() {
    return api.get(`/general/contact_us_page?take=3&skip=0`);
  },
  addContactUs(data) {
    return api.post(`/general/contact_us`, data);
  },
  cities(id) {
    return api.get(`/hotel-h1/city/${id}`);
  },
  nationalities() {
    return api.get(`/hotel-h1/nationalities`);
  },
};
