import {
  useLocation,
  matchPath
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';

export default function Sidebar() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const MenuItems = [
    {
      icon: "/icons/home.svg",
      title: t("sidebar.home"),
      to: "/",
      is_group_end: true,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/flights.svg",
      title: "Flights",
      to: "/flights",
      is_group_end: true,
      height: 25,
      width: 25,
    },
    // {
    //   icon: "/icons/d5cpk1l6olpz7hajtsbx.webp",
    //   title: t("sidebar.travelport"),
    //   to: "/flights/travelport",
    //   is_group_end: true,
    //   height: 20,
    //   width: 20,
    // },
    // {
    //   icon: "/icons/airariba.png",
    //   title: t("sidebar.airarabia"),
    //   to: "/flights/air-arabia",
    //   is_group_end: false,
    //   height: 20,
    //   width: 20,
    // },
    // {
    //   icon: "/icons/flybaghad.png",
    //   title: t("sidebar.flybgd"),
    //   to: "/flights/fly-baghdad",
    //   is_group_end: false,
    //   height: 20,
    //   width: 20,
    // },
    // {
    //   icon: "/icons/turkish.png",
    //   title: t("sidebar.turkish"),
    //   to: "/flights/turkish-airlines",
    //   is_group_end: false,
    //   height: 20,
    //   width: 20,
    // },
    // {
    //   icon: "/icons/PC.png",
    //   title: t("sidebar.pegasus"),
    //   to: "/flights/pegasus",
    //   is_group_end: false,
    //   height: 20,
    //   width: 20,
    // },
    // {
    //   icon: "/icons/FlyDubai-Logo.png",
    //   title: t("sidebar.flydubai"),
    //   to: "/flights/flydubai",
    //   is_group_end: true,
    //   height: 20,
    //   width: 20,
    // },
    {
      icon: "/icons/hotels.svg",
      title: t("sidebar.hotels"),
      to: "/hotels",
      is_group_end: false,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/group.svg",
      title: t("sidebar.groups"),
      to: "/groups",
      is_group_end: false,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/visa.svg",
      title: t("sidebar.visa"),
      to: "/visa",
      is_group_end: true,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/aboutus.svg",
      title: t("sidebar.aboutus"),
      to: "/abouts-us",
      is_group_end: false,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/contactus.svg",
      title: t("sidebar.contactus"),
      to: "/contact-us",
      is_group_end: false,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/faqs.svg",
      title: t("sidebar.faq"),
      to: "/faqs",
      is_group_end: true,
      height: 20,
      width: 20,
    },
    {
      icon: "/icons/privacy-policy-general.svg",
      title: t("sidebar.privacy"),
      to: "/privacy-policy",
      is_group_end: true,
      height: 20,
      width: 20,
    },
  ];

  return (
      <motion.div
          className="sticky top-0 h-screen bg-white z-30 border border-gray lg:w-[250px] bg-opacity-40"
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
      >
        {MenuItems.map((item, index) => (
            <motion.div
                key={index}
                whileHover={{ scale: 1.1 }}
                animate={matchPath(pathname, item.to) ? { scale: 1 } : {}}
            >
              <a
                  href={item.to}
                  className={`flex flex-row px-4 py-3 gap-4 items-center lg:flex-row lg:gap-6 hover:bg-[#ff690f] rounded-md duration-300 ${
                      matchPath(pathname, item.to) ? "bg-[#ff690f] opacity-80" : ""
                  } hover:opacity-80`}
              >
                <motion.img
                    src={require(`../../assets${item.icon}`)}
                    width={item.width}
                    height={item.height}
                    style={{ borderRadius: 5 }}
                    alt={item.title}
                />
                <span>{item.title}</span>
              </a>
              {item.is_group_end && (
                  <div className="w-full border-b border-[#F3F6F8] border-[1.1px] my-1"></div>
              )}
            </motion.div>
        ))}
      </motion.div>
  );
}
