import { lazy } from "react";
// project imports
import MainLayout from "../layouts/MainLayout";
import Loadable from "../components/Loaders/Loadable";
import GroupDetailsCountry from "../pages/groups/GroupDetailsCountry";

//home
const Home = Loadable(lazy(() => import("../pages/home")));
// Travelport
const Travelport = Loadable(lazy(() => import("../pages/flights/travelport")));
const TravelportSearch = Loadable(
  lazy(() => import("../pages/flights/travelport/search"))
);
//FlyBaghdad
const FlyBaghdad = Loadable(lazy(() => import("../pages/flights/fly-baghdad")));
const FlyBaghdadSearch = Loadable(
  lazy(() => import("../pages/flights/fly-baghdad/search"))
);
//AirArabia
const AirArabia = Loadable(lazy(() => import("../pages/flights/air-arabia")));
const AirArabiaSearch = Loadable(
  lazy(() => import("../pages/flights/air-arabia/search"))
);

//Global
const Global = Loadable(lazy(() => import("../pages/flights/global-flight")));

//TurkishAirlines
const TurkishAirlines = Loadable(
  lazy(() => import("../pages/flights/turkish-airlines/index"))
);
const TurkishAirlinesSearch = Loadable(
  lazy(() => import("../pages/flights/turkish-airlines/search"))
);

// flights
const Flights = Loadable(
    lazy(() => import("../pages/flights/flights/index"))
);
const FlightSearch = Loadable(
    lazy(() => import("../pages/flights/flights/search"))
);

// flydubai
const FlyDubai = Loadable(lazy(() => import("../pages/flights/flydubai")));
const FlyDubaiSearch = Loadable(
  lazy(() => import("../pages/flights/flydubai/search"))
);

// pegusus
const Pegasus = Loadable(
  lazy(() => import("../pages/flights/pegasus"))
);
const PegasusSearch = Loadable(
  lazy(() => import("../pages/flights/pegasus/search"))
);




//groups
const Groups = Loadable(lazy(() => import("../pages/groups")));
const GroupDetails = Loadable(
  lazy(() => import("../pages/groups/GroupDetails"))
);

//visa
const Visa = Loadable(lazy(() => import("../pages/visa")));
const VisaDetails = Loadable(lazy(() => import("../pages/visa/VisaDetails")));
//profile
const Profile = Loadable(lazy(() => import("../pages/profile")));
//Faqs
const Faqs = Loadable(lazy(() => import("../pages/faqs")));
//about-us
const AboutUs = Loadable(lazy(() => import("../pages/about-us")));
//contact-us
const ContactUs = Loadable(lazy(() => import("../pages/contact-us")));
//privacy-policy
const PrivacyPolicy = Loadable(lazy(() => import("../pages/privacy-policy")));
//privacy-policy
const Hotels = Loadable(lazy(() => import("../pages/hotels")));
const HotelDetails = Loadable(
  lazy(() => import("../pages/hotels/HotelDetails"))
);

// zaincash payment gateway callback
const ZaincashCallback = Loadable(lazy(() => import("../pages/payment/zaincash/CallBackTrans")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "flights",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Flights />,
      },
      {
        path: "search",
        element: <FlightSearch />,
      },
    ],
  },
  {
    path: "flights/travelport",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Travelport />,
      },
      {
        path: "search",
        element: <TravelportSearch />,
      },
    ],
  },
  {
    path: "flights/fly-baghdad",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <FlyBaghdad />,
      },
      {
        path: "search",
        element: <FlyBaghdadSearch />,
      },
    ],
  },
  {
    path: "flights/turkish-airlines",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <TurkishAirlines />,
      },
      {
        path: "search",
        element: <TurkishAirlinesSearch />,
      },
    ],
  },
  {
    path: "flights/flydubai",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <FlyDubai />,
      },
      {
        path: "search",
        element: <FlyDubaiSearch />,
      }
    ],
  },
  {
    path: "flights/pegasus",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Pegasus />,
      },
      {
        path: "search",
        element: <PegasusSearch />,
      }
    ],
  },
  {
    path: "flights/global",
    element: <MainLayout />,
    children: [
      {
        path: "search",
        element: <Global />,
      },
    ],
  },
  {
    path: "flights/air-arabia",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <AirArabia />,
      },
      {
        path: "search",
        element: <AirArabiaSearch />,
      },
    ],
  },
  {
    path: "groups",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Groups />,
      },
      {
        path: "/groups/:id",
        element: <GroupDetails />,
      },
      {
        path: "/groups/country/:id",
        element: <GroupDetailsCountry />,
      }
    ],
  },
  {
    path: "visa",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Visa />,
      },
      {
        path: "/visa/:id",
        element: <VisaDetails />,
      },
    ],
  },
  {
    path: "hotels",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Hotels />,
      },
      {
        path: "/hotels/:id",
        element: <HotelDetails />,
      },
    ],
  },
  {
    path: "profile",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  },
  {
    path: "faqs",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Faqs />,
      },
    ],
  },
  {
    path: "abouts-us",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <AboutUs />,
      },
    ],
  },
  {
    path: "contact-us",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "privacy-policy",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <PrivacyPolicy />,
      },
    ],
  },
  // add callback for zaincash payment gateway
  {
    path: "/zaincash/callback",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <ZaincashCallback />,
      },
    ],
  },
];
export default MainRoutes;
