import api from "../apiConfig";

const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getGroups(p) {
    const params = paramsFactory(p);
    return api.get(`/group?${params}`);
  },
  byid(id) {
    return api.get(`/group/${id}`);
  },
  book(data) {
    return api.post(`/group/book`, data);
  },
  getCountry() {
    return api.get(`/group/country`);
  },
};
