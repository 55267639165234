import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import { factory } from "../../api/apiFactory";
const visaApi = factory.get("visa");
const othersApi = factory.get("others");

const initialState = {
  filters: {
    take: 5,
    skip: 0,
    sort: "",
    search: "",
    country_id: "",
  },
  results: {
    loading: false,
    status: false,
    message: "",
    description: "",
    total: 0,
    data: [],
  },
  visa: {
    loading: false,
    status: false,
    message: "",
    description: "",
    data: {
      id: "",
      address: "",
      name: "",
      rate: null,
      desc: "",
      price: "",
      child_price: "",
      infant_price: "",
      days: "",
      image_main_url: "",
      visa_needs: [],
    },
  },
  book: {
    loading: false,
    status: false,
    message: "",
    description: "",
  },
  errors: "",
};

export const visaSlice = createSlice({
  name: "visa",
  initialState,
  reducers: {
    setResults(state, { payload }) {
      state.results = { ...payload };
      state.results.loading = false;
    },
    setVisaErrors(state, { payload }) {
      state.errors = payload;
    },
    setVisa(state, { payload }) {
      state.visa.data = { ...payload.data };
      state.visa.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.results.loading = !state.results.loading;
    },
    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setBookLoading: (state, { payload }) => {
      state.book.loading = !state.book.loading;
    }
  },
});

export const {
  setLoading,
  setResults,
  setVisa,
  setVisaErrors,
  setFilters,
  resetFilters,
  setBookLoading
} = visaSlice.actions;

export default visaSlice.reducer;

//axios
const getData = () => async (dispatch, getState) => {
  try {
    const filters = getState().visa.filters;
    dispatch(setLoading("results"));
    const res = await visaApi.getVisas(filters);
    dispatch(setResults(res.data));
  } catch (err) {
    dispatch(setLoading("results"));
    throw new Error(err);
  }
};
const getByID = (id) => async (dispatch, getState) => {
  try {
    // const filters = getState().groups.filters;
    dispatch(setLoading("visa"));
    const res = await visaApi.byid(id);
    dispatch(setVisa(res.data));
  } catch (err) {
    dispatch(setLoading("visa"));
    throw new Error(err);
  }
};

const book = (form, id, attachments) => async (dispatch) => {
  try {
    setBookLoading();
    const formData = new FormData();
    let existAttachments = false;

        // check on form if valid data
        form.forEach((element) => {
          console.log(element);
          // check the ExpireDate if valid or not and must be more than 6 months from now
          const expireDate = new Date(element.ExpireDate);
          const today = new Date();
          const diff = expireDate - today;
          const months = Math.floor(diff / 2592000000);
    
          if (months < 6) {
            toast.error("Please Enter Valid ExpireDate (more than 6 months)");
            return false;
          }
    
          // check the BirthDate if valid or not and must be more than 12 years for ADT
          if (element.PassengerType === "ADT") {
            const birthDate = new Date(element.BirthDate);
            const today = new Date();
            const diff = today - birthDate;
            const age = Math.floor(diff / 31557600000);
    
            if (age < 12) {
              toast.error("Please Enter Valid BirthDate for Adult (12+)");
              return false;
            }
          }
    
    
          // check the BirthDate if valid or not and must be more than 2 years and less than 12 years for CHD
    
          if (element.PassengerType === "CHD") {
            const birthDate = new Date(element.BirthDate);
            const today = new Date();
            const diff = today - birthDate;
            const age = Math.floor(diff / 31557600000);
    
            if (age < 2 || age > 12) {
              toast.error("Please Enter Valid BirthDate for Child (2-12)");
              return false;
            }
          }
    
          // check the BirthDate if valid or not and must be less than 2 years for INF
    
          if (element.PassengerType === "INF") {
            const birthDate = new Date(element.BirthDate);
            const today = new Date();
            const diff = today - birthDate;
            const age = Math.floor(diff / 31557600000);
    
            if (age > 2) {
              toast.error("Please Enter Valid BirthDate for Infant (<2)");
              return false;
            }
          }
    
          // check DocID if valid or not
          if (element.DocID === "") {
            toast.error("Please Enter Valid Passport Number");
            return false;
          }
    
    
          // check FirstName
          if (element.FirstName === "") {
            toast.error("Please Enter Valid First Name");
            return false;
          }
    
          // check LastName
          if (element.LastName === "") {
            toast.error("Please Enter Valid Last Name");
            return false;
          }
    
          // check NamePrefix
          if (element.NamePrefix === "") {
            toast.error("Please Enter Valid Name Prefix");
            return false;
          }
        });

    for (let i = 0; i < attachments.length; i++) {
      existAttachments = true;
      formData.append(`files`, attachments[i]);
    }

    if (!existAttachments) {
      toast.error("Please Upload Attachments");
      return false;
    }

    const bookInfo = {
      VISA_ID: id,
      passengers: form,
    };
    const book_res = await visaApi.book(bookInfo);

    if (book_res.data.status) {
      const res_success = book_res.data.data;

      formData.append("booking_id", res_success.id);
      formData.append("pnr", res_success.pnr);

      const attachments_res = await othersApi.attachments(formData);
      if (attachments_res.data.status) {
        toast.success("Successfully Booked!");
        dispatch(setVisaErrors(""));
        setBookLoading();
        window.location.href = "/profile";
        return true;
      } else {
        toast.error("Somthing went wrong uploading attachments !");
        dispatch(setVisaErrors(attachments_res.data.description));
        setBookLoading();
      }
    } else {
      toast.error("Somthing went wrong !");
      dispatch(setVisaErrors(book_res.data.description));
      setBookLoading();
    }
  } catch (err) {
    dispatch(setVisaErrors(err.response.data.message));
    toast.error("Somthing went wrong !");
    setBookLoading();
    throw new Error(err);
  }
};

export const visa = {
  getData,
  book,
  getByID,
};
