import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(data) {
    // const { from, to, departure, Return, adt, chd, inf, Class } = params;
    return api.post(`/flights/search-aggregator`, data);
  },
  sortFilter(data, searchTrackId) {
    return api.get(
      `/flights/search-aggregator/${searchTrackId}?sort=${data.sort}&filters=${data.queryFilters}`
    );
  },
};
