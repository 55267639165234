import api from "../apiConfig";

const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      const x = Array.isArray(p[key]) ? p[key].join(",") : p[key];

      params.append(key, x);
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(p) {
    const params = paramsFactory(p);
    return api.get(`/hotel-h1/search?${params}`);
  },

  mainInfo(id) {
    const main_info = api.get(`/hotel-h1/hotel-info?hotel_id=${id}`);
    return main_info;
  },
  description(id) {
    const description = api.get(`/hotel-h1/hotel-description?hotel_id=${id}`);
    return description;
  },
  mainImages(id) {
    const main_images = api.get(`/hotel-h1/hotel-main-image?hotel_id=${id}`);
    return main_images;
  },
  hotelImages(id) {
    const hotel_images = api.get(
      `/hotel-h1/hotel-images-collection?hotel_id=${id}`
    );
    return hotel_images;
  },
  offers(data) {
    return api.get(
      `/hotel-h1/hotel-offers?hotel_id=${data.hotel_id}&srk=${data.srk}&results_token=${data.results_token}`
    );
  },
  offerDetails(data) {
    return api.get(
      `/hotel-h1/hotel-show-offer-room?hotel_id=${data.hotel_id}&room_id=${data.room_id}&srk=${data.srk}&results_token=${data.results_token}&offer_id=${data.offer_id}`
    );
  },
  preBooking(data) {
    return api.post(
      `/hotel-h1/prebook?hotel_id=${data.hotel_id}&offer_id=${data.offer_id}&srk=${data.srk}&results_token=${data.results_token}`,
      data.data
    );
  },
  Booking(data) {
    return api.post(
      `/hotel-h1/book?hotel_id=${data.hotel_id}&offer_id=${data.offer_id}&srk=${data.srk}&results_token=${data.results_token}`,
      data.data
    );
  },
};
