import { Suspense } from "react";

// project imports
// import Loader from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<span>loading</span>}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
