import React, { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
// import {useSelector} from "react-redux";

const TawkTo = () => {
  const tawkMessengerRef = useRef();
    // const { profile } = useSelector((state) => state.profile);
  const onLoad = () => {
    // tawkMessengerRef.current.setAttributes({
    //     name : "Anonymous", // use the name if you know it
    //     email : "erre",
    // }, function(error) {
    //     console.log(error);
    // });
  };

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    // <div>
    //   <button onClick={handleMinimize}></button>
      <TawkMessengerReact
        propertyId="625e76637b967b11798b60c8"
        widgetId="1g10fstr8"
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />
    // </div>
  );
};

export default TawkTo;
