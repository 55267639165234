import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  search(params) {
    const { from, to, departure, Return, adt, chd, inf, Class } = params;
    return api.get(
      `/airarabia/search?from=${from}&to=${to}&departure=${departure}&return=${
        Return ? Return : null
      }&adt=${adt}&chd=${chd}&inf=${inf}&class=${Class}&searchType=lowfare`
    );
  },
  baggage(data) {
    return api.post(`/airarabia/get-baggage`, data);
  },
  meal(data) {
    return api.post(`/airarabia/get-meal`, data);
  },
  setMap(data) {
    return api.post(`/airarabia/seat-map`, data);
  },
  price(data) {
    return api.post(`/airarabia/get-price`, data);
  },
  book(data) {
    return api.post(`/airarabia/book`, data);
  },
};
