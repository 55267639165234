import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

//slices
import appSlice from "./slices/appSlice";
import travelportSlice from "./slices/travelportSlice";
import flyBaghdadSlice from "./slices/flyBaghdadSlice";
import profileSlice from "./slices/profileSlice";
import groupsSlice from "./slices/groupsSlice";
import visaSlice from "./slices/visaSlice";
import hotelsSlice from "./slices/hotelsSlice";
import airArabiaSlice from "./slices/airArabiaSlice";
import turkishSlice from "./slices/turkishSlice";
import flydubaiSlice from "./slices/flydubaiSlice";
import globalSlice from "./slices/globalSlice";


export const store = configureStore({
  reducer: {
    app: appSlice,
    travelport: travelportSlice,
    flyBaghdad: flyBaghdadSlice,
    groups: groupsSlice,
    profile: profileSlice,
    visa: visaSlice,
    hotels: hotelsSlice,
    airArabia: airArabiaSlice,
    turkish: turkishSlice,
    global: globalSlice,
    flydubai: flydubaiSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
