import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { groups } from "src/store/slices/groupsSlice";
import CardsLayoutV from "../components/Cards/CardsLayoutV";
import { useTranslation } from "react-i18next";
import { setFilters } from "src/store/slices/groupsSlice";


const Index = () => {
  const { t } = useTranslation();
  const { filters } = useSelector((state) => state.groups);
  const execute = useDispatch();
  const countryId = window.location.href.split("/").pop();

  useEffect(() => {
    execute(setFilters({ ...filters, country_id: countryId }));
    execute(groups.getData());
  }, []);



  return (
    <div className="flex flex-col py-4 gap-10 lg:gap-4">
      <h1 className="text-3xl font-bold">{t("sidebar.groups")}</h1>
      <div className="flex flex-col gap-4">
        <CardsLayoutV />
      </div>
    </div>
  );
};

export default Index;
